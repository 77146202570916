* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}
h1 , h2 ,h3 , h4 ,h5 ,h6 {
  margin: 0;
  padding: 0;
}


#root {
  height: 100%;
}

body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}