body {
  margin: 0;
  font-family: "Arial", serif;
}

@font-face{
  font-family: "Open Sans";
  src: local("OpenSans-LightItalic"),
  url("./assets/fonts/OpenSans/OpenSans-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
}

@font-face{
  font-family: "Open Sans";
  src: local("OpenSans-Light"),
  url("./assets/fonts/OpenSans/OpenSans-Light.ttf");
  font-style: normal;
  font-weight: 300;
}

@font-face{
  font-family: "Open Sans";
  src: local("OpenSans-Regular"),
  url("./assets/fonts/OpenSans/OpenSans-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face{
  font-family: "Open Sans";
  src: local("OpenSans-SemiBold"),
  url("./assets/fonts/OpenSans/OpenSans-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
}

@font-face{
  font-family: "Open Sans";
  src: local("OpenSans-Bold"),
  url("./assets/fonts/OpenSans/OpenSans-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face{
  font-family: "Open Sans";
  src: local("OpenSans-ExtraBold"),
  url("./assets/fonts/OpenSans/OpenSans-ExtraBold.ttf");
  font-style: normal;
  font-weight: 800;
}

@font-face{
  font-family: "Amatic SC";
  src: local("AmaticSC-Regular"),
  url("./assets/fonts/Amatic SC/AmaticSC-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face{
  font-family: "Amatic SC";
  src: local("AmaticSC-Bold"),
  url("./assets/fonts/Amatic SC/AmaticSC-Bold.ttf");
}

@font-face{
  font-family: "Amatic SC Regular";
  src: local("AmaticSC-Regular"),
  url("./assets/fonts/Amatic SC/AmaticSC-Regular.ttf");
}

@font-face{
  font-family: "Amatic SC Bold";
  src: local("AmaticSC-Bold"),
  url("./assets/fonts/Amatic SC/AmaticSC-Bold.ttf");
}

@font-face{
  font-family: "Davish";
  src: local("Davish Black"),
  url("./assets/fonts/Davisch/Davish Black.ttf");
}

@font-face{
  font-family: "Northwell";
  src: local("NORTHWELL"),
  url("./assets/fonts/Northwell/NORTHWELL.OTF");
}

@font-face{
  font-family: "Olive Display";
  src: local("OliveDisplay Thin"),
  url("./assets/fonts/Olive Display/OliveDisplay Thin.ttf");
}

@font-face{
  font-family: "Avocado Sans Bold";
  src: local("Avocado Sans Bold"),
  url("./assets/fonts/Avocado Sans Bold/Avocado Sans Bold.ttf");
}

@font-face{
  font-family: "Futura PT Light Italic";
  src: local("FuturaPTLightOblique"),
  url("./assets/fonts/FuturaPTLightOblique/FuturaPTLightOblique.otf");
}

@font-face{
  font-family: "Kalam Bold";
  src: local("Kalam Bold"),
  url("./assets/fonts/Kalam Bold/Kalam-Bold.ttf");
}

@font-face{
  font-family: "Montana";
  src: local("Montana"),
  url("./assets/fonts/Montana/Montana.otf");
}

@font-face{
  font-family: "Butler";
  src: local("Butler"),
  url("./assets/fonts/Butler/Butler_Regular.otf");
}

@font-face{
  font-family: "Amithen";
  src: local("Amithen"),
  url("./assets/fonts/Amithen/Amithen.ttf");
}

@font-face{
  font-family: "Gabriola";
  src: local("Gabriola"),
  url("./assets/fonts/Gabriola/Gabriola.ttf");
}

@font-face{
  font-family: "Remachine Script Personal Use";
  src: local("Remachine Script Personal Use"),
  url("./assets/fonts/RemachineScriptPersonalUse/Font RemachineScript_Personal_Use.ttf");
}

@font-face{
  font-family: "Roboto Slab";
  src: local("Roboto Slab"),
  url("./assets/fonts/Roboto Slab/Font RobotoSlab-VariableFont_wght.ttf");
}

@font-face{
  font-family: "Original Surfer Regular";
  src: local("Original Surfer Regular"),
  url("./assets/fonts/OriginalSurfer/Font_OriginalSurfer-Regular.ttf");
}

@font-face{
  font-family: "Sweet Someday";
  src: local("Sweet Someday"),
  url("./assets/fonts/Sweet Someday/Font Sweet Someday for(names).otf");
}

@font-face{
  font-family: "Cowboys 2.0";
  src: local("Cowboys 2.0"),
  url("./assets/fonts/Cowboys2.0/font Cowboys 2.0.otf");
}
